import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <h1>404: Not Found</h1>
)

export default NotFoundPage
